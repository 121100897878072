import React, { useCallback } from 'react';
import { RecoilRoot } from 'recoil';

import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/ModalsManager/states/atoms';
import View from '~/containers/ModalsManager/View';
import { defaultForUndefinedOrNull } from '~/utils/helper';

export default function ModalsManager() {
  const initializeState = useCallback(({ set }) => {
    set(
      sharedAtoms.currentCustomer,
      defaultForUndefinedOrNull(globalThis.CURRENT_USER_DATA, {})
    );
    set(
      atoms.authConfig,
      defaultForUndefinedOrNull(globalThis?.HV?.ShowAuthModal, {})
    );
  }, []);

  return (
    <RecoilRoot initializeState={initializeState}>
      <View />
    </RecoilRoot>
  );
}
