import React, { useCallback } from 'react';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';

import { HELMET_CONTEXT } from '~/containers/Header/constants';
import ErrorBoundary from '~/components/ErrorBoundary';
import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/ModalsManager/states/atoms';
import Container from '~/containers/Header/container';
import ModalsManager from '~/containers/ModalsManager';
import { defaultForUndefinedOrNull } from '~/utils/helper';

export default function Header() {
  const initializeState = useCallback(({ set }) => {
    set(
      sharedAtoms.currentCustomer,
      defaultForUndefinedOrNull(globalThis.CURRENT_USER_DATA, {})
    );
    set(
      atoms.authConfig,
      defaultForUndefinedOrNull(globalThis?.HV?.ShowAuthModal, {})
    );
  }, []);

  return (
    <RecoilRoot initializeState={initializeState}>
      <ErrorBoundary location="header">
        <HelmetProvider context={HELMET_CONTEXT}>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <ModalsManager />
            <Container />
          </CookiesProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
}
