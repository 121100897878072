import '~/wdyr';
import '~/polyfills';

import React, { StrictMode } from 'react';
import ReactDom from 'react-dom';
import Modal from 'react-modal';
import { GoogleOAuthProvider } from '@react-oauth/google';

import '~/i18n';
import Header from '~/containers/Header';
// import MalaysiaModal from '~/components/MalaysiaModal';
import '@/application.scss';

const dom = document.getElementById('headerPageApp');

Modal.setAppElement(dom);

ReactDom.render(
  <GoogleOAuthProvider clientId={globalThis.HV.GOOGLE_OAUTH_CLIENT_ID}>
    <StrictMode>
      <Header />
      {/* <MalaysiaModal /> */}
    </StrictMode>
  </GoogleOAuthProvider>,
  dom
);
